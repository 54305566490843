import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";
import React from "react";
import classnames from "classnames";
import { OptionType } from "../../Types";

const reactStringReplace = require("react-string-replace");

type Props = {
  context: string;
  tabs: OptionType[];
  onValidate?: () => void;
};

const KFTabGroup = (props: Props) => {
  const [currentTab, setCurrentTab] = React.useState("");
  return (
    <div>
      <Nav tabs>
        {props.tabs.map(({ value, description }) => {
          return (
            <NavItem key={value}>
              <NavLink
                className={classnames({
                  active: currentTab === value
                })}
                onClick={() => setCurrentTab(value)}
              >
                {description}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={currentTab} className="tab-content">
        {props.tabs.map(({ value, longdescription, link }) => {
          return (
            <TabPane tabId={value} key={value}>
              <Row>
                <Col sm="12">
                  {link != null ? (
                    <>
                      <a target="_blank" rel="noopener noreferrer" href={link}>
                        {link}
                      </a>
                      <br />
                    </>
                  ) : null}
                  {reactStringReplace(
                    longdescription,
                    "\n",
                    (match: string, i: number) => (
                      <br key={i} />
                    )
                  )}
                </Col>
              </Row>
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

export default KFTabGroup;
