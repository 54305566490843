import React from "react";
import { Container } from "reactstrap";
import useViewer from "../hooks/useViewer";
import ApplyApplication, { ApplicationProps } from "./ApplyApplication";
import yaml from "js-yaml";
import axios from "axios";
import "./Apply.css";
import "./Custom.css";

export type ContextTypeItem = {
  visible: boolean;
  value?: string;
  validated?: boolean;
};

export type ContextType = {
  [name: string]: ContextTypeItem;
};
export const ApplyContext = React.createContext<
  [ContextType, React.Dispatch<React.SetStateAction<ContextType>>]
>([{}, () => {}]);

const Apply = () => {
  const [loading, setLoading] = React.useState(true);
  const [state, setState] = React.useState<ContextType>(
    JSON.parse(window.localStorage.getItem("application") || "{}")
  );

  const [application, setApplication] = React.useState<ApplicationProps | null>(
    null
  );
  React.useEffect(() => {
    document.body.classList.add("custom");
    return () => document.body.classList.remove("custom");
  }, []);
  (async () => {
    if (application == null) {
      const req = await axios.get("./Application.yaml");
      const app = yaml.load(req.data);
      setApplication(app);
    }
  })();

  const { isLoggedIn } = useViewer();
  if (!isLoggedIn) {
    console.log("CURRENTLY not logged in but ignored");
    // window.location.href = "/login";
    // return null;
  }
  setTimeout(() => setLoading(false), 200);
  const shouldShowApp = process.env.REACT_APP_FORM_ENABLED !== "DISABLED";

  return (
    <Container
      className={
        loading ? "hidden loading mini-container" : "loading mini-container"
      }
    >
      <br/>
      <div className="titleText">
        <span className="titleTechX">
          <span className="titleTech">tech</span>x
        </span>{" "}
        | Application 2024-2025
        <div className="subtitleText">
        {/* Our application is now closed, if you have any questions or missed the deadline due to circumstances, email <a href = "ivyw@mit.edu">ivyw@mit.edu</a>! Have a good year :)
          <br/> 👩🏿‍💻👨🏼‍💻👨🏾‍💻🧑‍💻👨🏿‍💻🧑🏻‍💻🧑🏾‍💻👩🏻‍💻👩🏼‍💻🧑🏿‍💻👨‍💻👨🏻‍💻👨🏽‍💻👨🏿‍💻🧑🏼‍💻👩🏽‍💻👩🏾‍💻🧑🏽‍💻👩‍💻 */}
          <span className="subtitleSubmitBy">Submit by:</span> Friday, Sep. 6th
          @ 11:59 EST
        </div>
      </div>
      {application != null && shouldShowApp ? (
        <ApplyContext.Provider value={[state, setState]}>
          <ApplyApplication application={application} />
        </ApplyContext.Provider>
      ) : shouldShowApp ? (
        <div>Loading Application</div>
      ) : (
        <div>
          Our application is currently closed. Check back when applications open
          on August 30th! If you have any questions or missed the deadline due to circumstances, please email <a href="techx-exec@mit.edu">techx-exec@mit.edu</a>!
        </div>
      )}
    </Container>
  );
};

export default Apply;
