import React from "react";
import "react-table/react-table.css";
import "./Admin.css";
import {
  Container,
  Button,
  Badge,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  CustomInput,
  Input,
} from "reactstrap";
import ServerHelper, { ServerURL } from "./ServerHelper";
import useLogin from "../hooks/useLogin";
import ReactTable from "react-table";
import createAlert, { AlertType } from "./Alert";
import AppHeader from "../AppHeader";

type keybase = {
  [key: string]: string;
};
const Admin = () => {
  const { getCredentials } = useLogin();
  const [fullList, setFullList] = React.useState<keybase[]>([]);
  const [allKeys, setAllKeys] = React.useState<string[]>([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [filterKey, setFilterKey] = React.useState("_ALL_");
  const [filterValue, setFilterValue] = React.useState("");
  const [theOne, setTheOne] = React.useState<keybase | null>(null);
  const [tagEdit, setTagEdit] = React.useState<string>("");
  const [noteEdit, setNoteEdit] = React.useState<string>("");
  const [hideName, setHideName] = React.useState<boolean>(true);

  const toggleUser = React.useCallback(
    (newOne) => {
      if (newOne === theOne) {
        setTheOne(null);
      } else {
        setTheOne(newOne);
        setNoteEdit(newOne.notes);
      }
    },
    [theOne]
  );

  const tagCombo = ["willinterview", "accepted", "denied", "approved"];
  const myCommitees = ["hackmit", "makemit", "projx", "think", "xfair"];
  const allTags: string[] = [];
  myCommitees.forEach((name) => {
    tagCombo.forEach((tag) => {
      allTags.push(name + "-" + tag);
    });
  });

  React.useEffect(() => {
    (async () => {
      const resp = await ServerHelper.post(
        ServerURL.submissions,
        getCredentials()
      );
      if (resp && resp.success === false) {
        createAlert(
          AlertType.Warning,
          "Something went wrong! Are you admin and authed?"
        );
        return;
      }
      const applications = resp.forms.map((key: { [key: string]: string }) => ({
        ...JSON.parse(key.form),
        link: key.link,
        tags: key.tags,
        id: key.id,
        notes: key.notes,
      }));
      let keys: string[] = [];
      applications.forEach((data: keybase) => {
        keys = keys.concat(Object.keys(data));
      });
      setFullList(applications);
      setAllKeys(Array.from(new Set(keys)));
    })();
    // eslint-disable-next-line
  }, []);

  const filteredList = React.useMemo(() => {
    let finalList: keybase[] = fullList;
    if (filterKey !== "_ALL_") {
      finalList = finalList.filter((one: keybase) => {
        return one[filterKey] && (one[filterKey] + "").includes(filterValue);
      });
    }
    if (searchValue.length > 0) {
      const value = searchValue.toLowerCase();
      finalList = finalList.filter((one: keybase) => {
        return allKeys.reduce((prev: boolean, current) => {
          if (one[current] != null) {
            return (one[current] + "").toLowerCase().includes(value) || prev;
          }
          return prev;
        }, false);
      });
    }
    return finalList;
    // eslint-disable-next-line
  }, [fullList, filterKey, filterValue, searchValue]);

  const columns = [
    {
      Header: "Options",
      Cell: (props: { original: null }) => (
        <>
          <Button
            onClick={(e) => toggleUser(props.original)}
            color={theOne === props.original ? "info" : "danger"}
          >
            View
          </Button>
        </>
      ),
      maxWidth: 80,
    },
    { Header: "ID", accessor: "id" },
    hideName ? {} : { Header: "Name", accessor: "0_name" },
    {
      Header: "Tags",
      accessor: "tags",
      Cell: (row: { value: string }) => {
        return row.value.split(";").map((tag: string) => {
          if (tag == null || tag.length === 0) {
            return null;
          }
          return (
            <span key={tag}>
              <Badge color="primary">{tag}</Badge>{" "}
            </span>
          );
        });
      },
    },
    { Header: "Notes", accessor: "notes" },
  ].filter((x) => x.Header);
  const filterView = (
    <>
      <InputGroup>
        <InputGroupAddon addonType="prepend">Filter By:</InputGroupAddon>
        <InputGroupAddon addonType="prepend">
          <CustomInput
            id="filter_key_input"
            type="select"
            name="filterKey"
            value={filterKey}
            onChange={(event) => setFilterKey(event.target.value)}
          >
            <option key="NO FILTER" value="_ALL_">
              No Filter
            </option>
            {allKeys.map((val) => {
              return (
                <option key={val} value={val}>
                  {val}
                </option>
              );
            })}
          </CustomInput>
          <Input
            name="filterValue"
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
            placeholder="string"
          />
        </InputGroupAddon>

        <InputGroupAddon addonType="append">Hide PPI?</InputGroupAddon>
        <CustomInput
          type="select"
          onChange={(event) => setHideName(event.target.value === "true")}
        >
          <option key={1} value={"true"} defaultChecked={true}>
            True
          </option>
          <option key={1} value={"false"}>
            False
          </option>
        </CustomInput>
      </InputGroup>
      <Input
        name="searchValue"
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder="Search..."
      />
    </>
  );

  const editTags = async (shouldAdd: boolean) => {
    const resp = await ServerHelper.post(ServerURL.edit, {
      ...getCredentials(),
      action: "tags",
      oneid: theOne && theOne["id"],
      valueString: tagEdit,
      valueBool: shouldAdd,
    });
    if (resp && resp.success === false) {
      createAlert(AlertType.Warning, "Could not save");
      return;
    }
    if (theOne) {
      theOne["tags"] = resp.tags;
      setTheOne(null);
      setTheOne(theOne);
    }
  };

  const editNotes = async () => {
    const resp = await ServerHelper.post(ServerURL.edit, {
      ...getCredentials(),
      action: "notes",
      oneid: theOne && theOne["id"],
      valueString: noteEdit,
    });
    if (resp && resp.success === false) {
      createAlert(AlertType.Warning, "Could not save");
      return;
    }
    if (theOne) {
      theOne["notes"] = resp.notes;
      setTheOne(null);
      setTheOne(theOne);
    }
  };

  const userView =
    theOne != null ? (
      <Col xs="6" className="GridView-PDFContainer">
        <h2 className="text-center">
          {hideName ? "NAME HIDDEN" : theOne["0_name"]}
        </h2>
        <h4>Data:</h4>
        <p>
          {Object.keys(theOne).map((key: string) => {
            if ((key.includes("0_") || key === "email") && hideName) return;
            return (
              <>
                <b>{key}:</b> {theOne[key]}
                <br />
              </>
            );
          })}
        </p>
        <Row>
          <CustomInput
            id="filter_year_input"
            type="select"
            name="filterYear"
            value={tagEdit}
            onChange={(event) => setTagEdit(event.target.value)}
          >
            <option key="" value="">
              ----
            </option>
            {allTags.map((val) => {
              return (
                <option key={val} value={val}>
                  {val}
                </option>
              );
            })}
          </CustomInput>
          <Button onClick={() => editTags(false)}>Remove Tag</Button>
          <Button onClick={() => editTags(true)}>Add Tag</Button>
        </Row>
        <br />
        <p>
          Note, people cannot simultaneously edit notes! Nor does it immediately
          refresh. Would recommend refresh immediately add the document and then
          save :)
        </p>
        <Row>
          <Input
            name="notesEdit"
            value={noteEdit}
            onChange={(event) => setNoteEdit(event.target.value)}
            placeholder="string"
            type="textarea"
          />
          <Button onClick={editNotes}>Update Notes</Button>
        </Row>
      </Col>
    ) : null;

  return (
    <>
      <AppHeader />
      <div className="adminView">
        <Row className="GridView-row">
          <Col xs={theOne == null ? "12" : "6"} className="GridView-flex">
            <Container>
              {filterView}
              <ReactTable
                data={filteredList}
                columns={columns}
                defaultPageSize={10}
              />
            </Container>
          </Col>
          {userView}
        </Row>
      </div>
    </>
  );
};

export default Admin;
