import { useCookies } from "react-cookie";
import ServerHelper, { ServerURL } from "../components/ServerHelper";

const useLogin = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    "uid",
    "email",
    "name",
  ]);

  const redirectToDopeAuth = async (opt?: {
    spoofLogin: boolean;
    email: string;
  }) => {
    if (opt && opt.spoofLogin) {
      await login("SPOOFED", opt.email, "SPOOFED", { spoofLogin: true });
    } else {
      window.location.href =
        "https://dopeauth.com/login/" +
        encodeURIComponent(process.env.REACT_APP_CALLBACKURL || "");
    }
  };
  const login = async (
    uid: string,
    email: string,
    token: string,
    opt?: { spoofLogin: boolean }
  ): Promise<Boolean> => {
    
    if (
      (opt && opt.spoofLogin) ||
      (await loginWithDopeAuth(uid, email, token))
    ) {
      const json = await ServerHelper.post(ServerURL.verify, {
        email: email,
        token: token,
        uid: uid,
      });
      if (json["success"]) {
        setCookie("name", json["name"] || "____", { path: "/" });
        setCookie("year", json["year"] || "0000", { path: "/" });
        setCookie("email", email, { path: "/" });
        setCookie("token", token, { path: "/" });
        setCookie("uid", uid, { path: "/" });
        return true;
      }
    }
    return false;
  };
  const loginWithDopeAuth = async (
    uid: string,
    email: string,
    token: string
  ): Promise<Boolean> => {
    try {
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          token: token,
          uid: uid,
        }),
      };
      // This is a client side check, however server side checks are also necessary!
      // Here we don't do a callback verify but on server side we want it
      const response = await fetch(
        "https://dopeauth.com/api/v1/site/verify",
        config
      );
      if (response.ok) {
        const json = await response.json();
        if (json["success"]) {
          setCookie("token", token, { path: "/" });
          setCookie("uid", uid, { path: "/" });
          setCookie("email", email, { path: "/" });
          return true;
        }
      }
    } catch (error) {}
    return false;
  };
  const getCredentials = () => {
    return {
      email: cookies["email"],
      uid: cookies["uid"],
      token: cookies["token"],
    };
  };
  const logout = () => {
    removeCookie("name", { path: "/" });
    removeCookie("year", { path: "/" });
    removeCookie("token", { path: "/" });
    removeCookie("uid", { path: "/" });
    removeCookie("email", { path: "/" });
  };
  return { redirectToDopeAuth, getCredentials, login, logout };
};

export default useLogin;
