import React from "react";
import { RouteComponentProps } from "react-router";
import useLogin from "../hooks/useLogin";
import useViewer from "../hooks/useViewer";
import { Container } from "reactstrap";

enum Status {
  start,
  loading,
  failed,
  succeed
}

const LoginCallback = (props: RouteComponentProps) => {
  const { isLoggedIn } = useViewer();
  const [loginStatus, setLoginStatus] = React.useState<Status>(
    isLoggedIn ? Status.succeed : Status.start
  );
  const search = new URLSearchParams(props.location.search);
  const { login, logout, redirectToDopeAuth } = useLogin();

  React.useEffect(() => {
    document.body.classList.add("custom");
    return () => document.body.classList.remove("custom");
  }, []);

  React.useEffect(() => {
    const uid = search.get("uid");
    const token = search.get("token");
    const email = search.get("email");
    if (loginStatus === Status.succeed) {
      window.location.replace("/admin");
    }
    if (
      uid != null &&
      token != null &&
      email != null &&
      loginStatus === Status.start
    ) {
      setLoginStatus(0);
      login(uid, email, token).then((success: Boolean) => {
        setLoginStatus(success ? Status.succeed : Status.failed);
      });
    } else if (loginStatus === Status.start) {
      setLoginStatus(Status.failed);
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  if (loginStatus === Status.start || loginStatus === Status.loading) {
    return <div>Attempting to login</div>;
  } else if (loginStatus === Status.succeed) {
    return <div>Successful Login</div>;
  } else {
    return (
      <Container>
        <br />
        Login failed <br />
        Make sure you are logged into your MIT email <br />
        If this doesn't work email
        <a href="mailto:ivyw@mit.edu"> this person!</a>
        <br />
        <br />
        <button
          className="btn btn-primary"
          onClick={() => {
            logout();
            redirectToDopeAuth();
          }}
        >
          Relogin
        </button>
      </Container>
    );
  }
};

export default LoginCallback;
