import React from "react";
import { ApplyContext } from "./Apply";
import KFInput from "./form/KFInput";
import KFPage from "./form/KFPage";
import createAlert, { AlertType } from "./Alert";
import { Button, Progress, Row, Container } from "reactstrap";
import KFSelect from "./form/KFSelect";
import { TransitionGroup } from "react-transition-group";
import { useApplyContextEditor } from "../hooks/useApplyContext";
import useViewer, { Query } from "../hooks/useViewer";
import useLogin from "../hooks/useLogin";
import KFTabGroup from "./form/KFTabGroup";
import ServerHelper, { ServerURL } from "./ServerHelper";
import { QuestionType, OptionType } from "../Types";

enum Page {
  committee = "page_committee",
  introduction = "page_introduction",
  committeeQuestions = "page_committeeQuestions",
  subcommittee = "page_subcommittee",
  general = "page_general_questions",
  done = "page_done",
}

export type ApplicationProps = {
  committees: OptionType[];
  hackMITsubcommittees: OptionType[];
  subcommittees: OptionType[];
};

type Props = {
  application: ApplicationProps;
};

const ApplyApplication = ({ application }: Props) => {
  const [applycontext] = React.useContext(ApplyContext);
  const { isLoggedIn, viewer } = useViewer();
  const { redirectToDopeAuth, getCredentials, logout } = useLogin();
  const { setProgress, setVisibility, saveApplication, getValue, setValue } =
    useApplyContextEditor();

  // Saves Application on refresh
  React.useEffect(() => {
    const action = () => saveApplication();
    window.addEventListener("beforeunload", action);
    return () => {
      window.removeEventListener("beforeunload", action);
    };
  }, [saveApplication]);

  React.useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const isActualUser = (viewer(Query.name) || "") == "ADMIN";
    if (isActualUser) {
      window.location.href = "/admin";
    }
  }, []);

  // if (
  //   !isLoggedIn &&
  //   parseInt(
  //     (applycontext["REACT_PROGRESS"] &&
  //       applycontext["REACT_PROGRESS"].value) ||
  //       "0"
  //   ) > 0
  // ) {
  //   redirectToDopeAuth({ spoofLogin: true });
  //   return null;
  // }

  let userinfo = null;
  if (isLoggedIn) {
    if (viewer(Query.name)) {
      userinfo = (
        <div>
          <p>
            Name: {viewer(Query.name)}
            <br />
            Email: {viewer(Query.email)}
            <br />
            {/* Year: {viewer(Query.year)} */}
            <KFSelect
              description="Year: "
              context="year"
              options={[
                { value: "2028", description: "2028" },
                { value: "2027", description: "2027" },
                { value: "2026", description: "2026" },
                { value: "2025", description: "2025" },
              ]}
            />
          </p>
        </div>
      );
    } else {
      userinfo = (
        <div>
          <p>
            <KFInput question="Name:" context="name" inline />
            Email: {viewer(Query.email)}
            <br />
            <KFSelect
              description="Year: "
              context="year"
              options={[
                { value: "2028", description: "2028" },
                { value: "2027", description: "2027" },
                { value: "2026", description: "2026" },
                { value: "2025", description: "2025" },
              ]}
            />
          </p>
        </div>
      );
      /* NO INVALID EMAILS */
      userinfo = null;
    }
  }
  const getName = () => {
    if (isLoggedIn) {
      if (viewer(Query.name)) {
        return "Continue as " + viewer(Query.name);
      } else {
        return "Continue";
      }
    }
    return "Let's start";
  };
  const introduction = (
    <KFPage
      title=""
      description="
          MIT TechX is a student group that seeks to empower students through
          technology. We run a number of events each year, including 2
          hackathons, a make-athon, a student project expo, and a career fair. Outside of
          running events, we're also a great community -- we host lots of
          socials for students to get to know each other, and many of our alums
          go on to do great things in the tech world! "
      context={Page.introduction}
      visible={true}
      onNext={async () => {
        if (!isLoggedIn || getValue("0_email") != viewer(Query.email)) {
          await redirectToDopeAuth({
            spoofLogin: true,
            email: getValue("0_email"),
          });
          setValue("locked", "yes");
          return;
        }
        if (viewer(Query.name) == null || viewer(Query.name) == "____") {
          logout();
          setValue("locked", "no");
          alert("hmmm make sure you use a real MIT email");
          return;
        }
        setVisibility(Page.committee, true);
        setVisibility(Page.introduction, false);
        setProgress("25");
        setValue("0_name", viewer(Query.name));
        setValue("0_year", viewer(Query.year));

        setValue("REACT_VERSION", "2");
      }}
      onNextTitle={getName()}
    >
      <p>There are 3 parts to this application</p>
      <ul>
        <li>Committee Application Questions</li>
        <li>Specialized Committee Role Questions</li>
        <li>TechX General Questions</li>
      </ul>
      <p>
        If you have questions feel free to reach out at{" "}
        <a href="techx-exec@mit.edu">techx-exec@mit.edu</a>
      </p>
      {getValue("locked") == "yes" ? null : (
        <KFInput
          key={"0_email"}
          context={"0_email"}
          question={"MIT email (with @mit.edu)"}
          isTextArea={false}
          inline={true}
        />
      )}
      {userinfo}
    </KFPage>
  );

  const updateCommitteeQuestions = () => {
    setVisibility("hackmit_subcommittee_questions", false);
    if (
      getValue("general_committee") === "hackmit" ||
      getValue("general_committee_second_choice") === "hackmit"
    ) {
      setVisibility("hackmit_subcommittee_questions", true);
    }

    application.committees.forEach(({ value }) => {
      setVisibility(value + "_questions", false);
    });
    setVisibility(applycontext["general_committee"].value + "_questions", true);
    setVisibility(
      applycontext["general_committee_second_choice"].value + "_questions",
      true
    );
  };
  const validateQuestion = (questions: QuestionType[]) => {
    let isValidated = true;
    questions.forEach(({ context, questionMinLength, questionMaxLength }) => {
      const val = getValue(context);
      const minLength = questionMinLength || 0;
      const maxLength = questionMaxLength || 3000;
      if (val == null || val.length <= minLength || val.length > maxLength) {
        isValidated = false;
      }
    });
    return isValidated;
  };

  const displayQuestion = (
    title: string,
    context_prefix: string,
    questions: QuestionType[]
  ) => {
    return (
      <KFPage
        title={title}
        context={context_prefix}
        visible={false}
        key={context_prefix}
      >
        {questions.map(({ context, question }: QuestionType) => (
          <KFInput
            key={context}
            context={context}
            question={question}
            isTextArea={true}
          />
        ))}
      </KFPage>
    );
  };

  const updateHackMITSubCommitteeQuestions = () => {
    application.hackMITsubcommittees.forEach(({ value }) => {
      setVisibility("hackmit_" + value + "_questions", false);
    });
    setVisibility(
      "hackmit_" + applycontext["hackmit_subcommittee"].value + "_questions",
      true
    );
    setVisibility(
      "hackmit_" +
        applycontext["hackmit_subcommittee_second_choice"].value +
        "_questions",
      true
    );
  };

  const hackMITCommitteeQuestions = (
    <KFPage
      title="HackMIT Committee Questions"
      description="HackMIT is the biggest committee in TechX and thus has 4 subcommittees. Which do you think you would be the best fit for? (Pick up to 2)."
      context="hackmit_subcommittee_questions"
      visible={false}
    >
      <KFTabGroup
        tabs={application.hackMITsubcommittees}
        context="hackmit_subcommittee_descriptions"
      />
      <KFSelect
        onChange={updateHackMITSubCommitteeQuestions}
        description={"First Committee Choice:"}
        options={application.hackMITsubcommittees}
        context="hackmit_subcommittee"
      />
      <KFSelect
        description={"Second preference:"}
        onChange={updateHackMITSubCommitteeQuestions}
        options={application.hackMITsubcommittees}
        context="hackmit_subcommittee_second_choice"
      />
      {application.hackMITsubcommittees.map(
        ({ questions, value, description }) => {
          return displayQuestion(
            "HackMIT " + description + " Specific Questions",
            "hackmit_" + value + "_questions",
            questions
          );
        }
      )}
    </KFPage>
  );

  /* COMMITTEEE QUESTIONS ******/
  const committeeQuestionsPage = (
    <KFPage
      title=""
      context={Page.committeeQuestions}
      visible={false}
      onNext={() => {
        if (
          applycontext["hackmit_subcommittee_questions"].visible &&
          getValue("hackmit_subcommittee") === ""
        ) {
          const alertString = "Please choose one primary hackMIT subcommittee";
          createAlert(AlertType.Warning, alertString);
          return;
        }

        let hasFailed = false;
        application.committees.forEach(({ value, questions }) => {
          const val = applycontext[value + "_questions"];
          if (val.visible === true && !validateQuestion(questions)) {
            hasFailed = true;
          }
        });

        if (applycontext["hackmit_subcommittee_questions"].visible) {
          application.hackMITsubcommittees.forEach(({ value, questions }) => {
            const val = applycontext["hackmit_" + value + "_questions"];
            if (val.visible === true && !validateQuestion(questions)) {
              hasFailed = true;
            }
          });
        }

        if (hasFailed) {
          createAlert(
            AlertType.Warning,
            "You did not complete subquestions (or you might have too short/long responses!)"
          );
          return;
        }
        setVisibility(Page.general, true);
        setVisibility(Page.committeeQuestions, false);
        setProgress("75");
      }}
      onBack={() => {
        setVisibility(Page.committee, true);
        setVisibility(Page.committeeQuestions, false);
        setProgress("25");
      }}
    >
      {application.committees.map(({ questions, value, description }) => {
        return (
          <>
            {displayQuestion(
              description + " Specific Questions",
              value + "_questions",
              questions
            )}
            {value === "hackmit" ? hackMITCommitteeQuestions : null}
          </>
        );
      })}
    </KFPage>
  );

  const committeePage = (
    <KFPage
      title="Committees"
      description="You may apply to 5 distinct committees organizing 5 total events as part
      of TechX. Rank up to two committees you would like to apply to, and answer their questions.
      Click on the committee to read its description!"
      context={Page.committee}
      visible={false}
      onNext={() => {
        if (applycontext["general_committee"].value === "") {
          createAlert(AlertType.Warning, "Please choose one primary committee");
          return;
        }
        if (
          applycontext["general_committee"].value ===
          applycontext["general_committee_second_choice"].value
        ) {
          createAlert(AlertType.Warning, "Committees cannot be the same");
          return;
        }
        setVisibility(Page.committeeQuestions, true);
        setVisibility(Page.committee, false);
        setProgress("50");
      }}
      onBack={() => {
        setVisibility(Page.committee, false);
        setVisibility(Page.introduction, true);
        setProgress("0");
      }}
    >
      <KFTabGroup
        tabs={application.committees}
        context="committee_descriptions"
      />
      <br />
      <br />
      <KFSelect
        onChange={updateCommitteeQuestions}
        description={"First Committee Choice"}
        options={application.committees}
        context="general_committee"
      />
      <KFSelect
        description={"Second preference: (optional)"}
        onChange={updateCommitteeQuestions}
        options={application.committees}
        context="general_committee_second_choice"
      />
      <br />
    </KFPage>
  );

  const updateSubCommitteeQuestions = () => {
    application.subcommittees.forEach(({ value }) => {
      setVisibility("subcommittee_" + value + "_questions", false);
    });
    setVisibility(
      "subcommittee_" +
        applycontext["general_committee_subcommittee"].value +
        "_questions",
      true
    );
    setVisibility(
      "subcommittee_" +
        applycontext["general_committee_subcommittee_second_choice"].value +
        "_questions",
      true
    );
  };

  const subCommitteePage = (
    <KFPage
      title="Specialized Committee Roles"
      description="All of our event-based committees will have members who will have a more specific focus
       on each of the following: development and operations (devops), finance, internal relations, and marketing.
       If you are selected for an interview to be on an event-based committee,
       and have indicated interest in a specific role as well,
       we will ask you both general and committee-specific questions."
      visible={false}
      context={Page.subcommittee}
      onNext={() => {
        if (
          getValue("general_committee_subcommittee") !== "" &&
          getValue("general_committee_subcommittee") ===
            getValue("general_committee_subcommittee_second_choice")
        ) {
          const alertString = "Specialized committees cannot be the same";
          createAlert(AlertType.Warning, alertString);
          return;
        }
        setVisibility(Page.subcommittee, false);
        setVisibility(Page.general, true);
        setProgress("90");
      }}
      onBack={() => {
        setVisibility(Page.subcommittee, false);
        setVisibility(Page.committeeQuestions, true);
        setProgress("20");
      }}
    >
      <KFTabGroup
        tabs={application.subcommittees}
        context="committee_descriptions"
      />
      <br />
      <br />
      <KFSelect
        onChange={updateSubCommitteeQuestions}
        description={"First subcommittee choice: (optional)"}
        options={application.subcommittees}
        context="general_committee_subcommittee"
      />
      <KFSelect
        onChange={updateSubCommitteeQuestions}
        description={"Second preference: (optional)"}
        options={application.subcommittees}
        context="general_committee_subcommittee_second_choice"
      />
      {application.subcommittees &&
        application.subcommittees
          .filter((x) => x.questions)
          .map(({ questions, value, description }) => {
            return displayQuestion(
              description + " Specific Questions",
              "subcommittee_" + value + "_questions",
              questions
            );
          })}
    </KFPage>
  );
  const onSubmit = async () => {
    if (
      !window.confirm(
        "do you want to submit your application? You won't be able to change your responses"
      )
    ) {
      return false;
    }
    const resp = await ServerHelper.post(ServerURL.submit, {
      ...getCredentials(),
      form: JSON.stringify(applycontext),
    });
    if (resp.success) {
      createAlert(AlertType.Success, "Your application has been submitted!");
      setVisibility(Page.done, true);
      setVisibility(Page.general, false);
      return true;
    } else {
      createAlert(
        AlertType.Error,
        "Your application was not able to be subbmitted!"
      );
      return false;
    }
  };
  const generalQuestions = (
    <KFPage
      title="Final Questions"
      context="page_general_questions"
      visible={false}
      onBack={() => {
        setProgress("50");
        setVisibility(Page.committeeQuestions, true);
        setVisibility(Page.general, false);
      }}
      onNext={async () => {
        if (getValue("general_interview_conflicts").length === 0) {
          createAlert(
            AlertType.Warning,
            'If you do not have a conflict put "N/A", otherwise state your conflict!'
          );
          return;
        }
        const complete = await onSubmit();
        if (complete) {
          setProgress("100");
          return;
        }
      }}
      onNextTitle="Submit"
    >
      <KFInput
        question="Write a sentence about why TechX excites you. (40 words max)"
        isTextArea={true}
        context="general_excite"
      />
      <KFInput
        question={
          "We'll be holding interviews Saturday September 7th and Sunday September 8th starting at 11AM. Please write any times and reasons that you *can not* be interviewed below. If you've been selected for an interview, you'll receive your interview time early Saturday morning, so keep your eyes peeled!"
        }
        context="general_interview_conflicts"
      />
      <KFInput question={"Github: (optional)"} context="general_github" />
      <KFInput question={"Portfolio: (optional)"} context="general_portfolio" />
      <KFInput
        question="What other commitments/interests do you expect to have during the year? 
        (Greek life, sports, internships, research, etc.) (optional)"
        isTextArea={true}
        context="general_commitments"
      />
      <KFInput
        question="Anything else you want to add? (projects, random fun facts, comments, questions, jokes, etc.) (optional)"
        isTextArea={true}
        context="general_else"
      />
    </KFPage>
  );

  const done = (
    <KFPage title="Application Submitted!" context={Page.done} visible={false}>
      <p>Your application has been submitted. You'll hear back shortly!</p>
      <p>
        If you've been selected for an interview, you'll receive your interview
        time early Saturday morning, so be sure to wake up early and check your
        emails!
      </p>
      <p>
        If your availability changes or if you have any questions feel free to
        reach out at <a href="mailto:techx-exec@mit.edu">techx-exec@mit.edu</a>
      </p>
      <p>
        <Button
          onClick={() => {
            logout();
            if (saveApplication(true)) {
              createAlert(
                AlertType.Success,
                "Your application has been cleared!"
              );
            }
          }}
        >
          Restart Application (New Application)
        </Button>
      </p>
    </KFPage>
  );

  return (
    <>
      <TransitionGroup className="content">
        {introduction}
        {committeePage}
        {committeeQuestionsPage}
        {generalQuestions}
        {done}
      </TransitionGroup>
      {getValue("REACT_PROGRESS") === "100" &&
      !(
        process.env.REACT_APP_CALLBACKURL &&
        process.env.REACT_APP_CALLBACKURL.includes("localhost")
      ) ? null : (
        <Container>
          <br />
          <Progress
            value={
              (applycontext["REACT_PROGRESS"] &&
                applycontext["REACT_PROGRESS"].value) ||
              "0"
            }
          />
          <Row className="mt-2">
            <Button
              size="sm"
              onClick={() => {
                saveApplication();
                createAlert(
                  AlertType.Success,
                  "Your application has been saved!"
                );
              }}
              className="col-6"
              color="info"
            >
              Save Application
            </Button>{" "}
            <Button
              size="sm"
              className="col-6"
              onClick={() => {
                logout();
                if (saveApplication(true)) {
                  createAlert(
                    AlertType.Success,
                    "Your application has been cleared!"
                  );
                }
              }}
            >
              Restart Application
            </Button>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ApplyApplication;
